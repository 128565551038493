
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'
import { QuestionAnswer } from '@/lib/loci'

const loci = namespace('loci')
import LociModule from '@/store/modules/loci'
import QuestionAnswersForm from '@/components/QuestionAnswersForm.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'question-answers-dialog',
  components: {
    QuestionAnswersForm,
  },
})
export default class QuestionAnswersDialog extends Props {
  get showQuestionAnswersDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showQuestionAnswersDialog
  }

  set showQuestionAnswersDialog(show: boolean) {
    const module = getModule(LociModule, this.$store);
    module.setShowQuestionAnswersDialog(show)
  }

  get currentQuestionAnswer(): QuestionAnswer | null {
    const module = getModule(LociModule, this.$store);
    return module.currentQuestionAnswer
  }

  get currentQuestionAnswers(): QuestionAnswer[] | null {
    const module = getModule(LociModule, this.$store);
    return module.currentQuestionAnswers
  }

  get currentQuestionAnswersIndex(): number {
    const module = getModule(LociModule, this.$store);
    return module.currentQuestionAnswersIndex
  }

  get isLastQuestionAnswer(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.isLastQuestionAnswer
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowQuestionAnswersDialog!: (show: boolean) => void

  /* eslint-enable no-unused-vars */

  submitAnswer() {
    const form = this.$refs.form as QuestionAnswersForm
    if (form) {
      form.onSubmitAnswer()
    }
  }
}
