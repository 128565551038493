
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const loci = namespace('loci')

const Props = Vue.extend({
  props: {
    question: Array,
  }
})

@Component({
  name: 'question-answers-form',
})
export default class QuestionAnswersForm extends Props {
  answer = ''

  /* eslint-disable no-unused-vars */
  @loci.Action
  submitAnswer!: (payload: {answer: string}) => void
  /* eslint-enable no-unused-vars */

  onSubmitAnswer() {
    this.submitAnswer({answer: this.answer})
    this.answer = ''
  }
}
