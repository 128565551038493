
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import { Note } from '@/lib/loci'
const loci = namespace('loci')

const Props = Vue.extend({
  props: {
    note: Object as PropType<Note>,
  }
})

@Component({
  name: 'note-actions',
})
export default class NoteActions extends Props {
  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setCurrentNote!: (note: Note | null) => void

  @loci.Mutation
  setShowNoteEditDialog!: (show: boolean) => void

  @loci.Action
  removeNote!: (note: Note) => void

  @loci.Action
  showNote!: (note: Note) => void

  @loci.Action
  reviewNote!: (note: Note) => void
  /* eslint-enable no-unused-vars */

  remove() {
    this.removeNote(this.note)
  }

  show() {
    this.showNote(this.note)
  }

  review() {
    this.reviewNote(this.note)
  }
}
