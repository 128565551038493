
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const loci = namespace('loci')
import { Note } from '@/lib/loci'
import LociModule from '@/store/modules/loci'
import MarkdownPreview from '@/components/MarkdownPreview.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-preview-dialog',
  components: {
    MarkdownPreview,
  },
})
export default class NotePreviewDialog extends Props {
  get currentNote(): Note | null {
    const module = getModule(LociModule, this.$store);
    return module.currentNote
  }

  get showNotePreviewDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showNotePreviewDialog
  }

  set showNotePreviewDialog(show: boolean) {
    const module = getModule(LociModule, this.$store);
    module.setShowNotePreviewDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowNotePreviewDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */
}
