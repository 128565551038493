
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const loci = namespace('loci')
import LociModule from '@/store/modules/loci'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'numbers-exercise-dialog',
  components: {
  },
})
export default class NumbersExerciseDialog extends Props {
  randomNumber = 0
  digits = 4
  showRandomNumber = true
  answer = ''
  correct = false
  showResult = false
  hideResultTimeout = -1

  created() {
    this.digits = 4 + Math.floor(Math.random() * 12)
    this.generateRandomNumber()
  }

  get showNumbersExerciseDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showNumbersExerciseDialog
  }

  set showNumbersExerciseDialog(show: boolean) {
    const module = getModule(LociModule, this.$store);
    module.setShowNumbersExerciseDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowNumbersExerciseDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  generateRandomNumber(): void {
    const random = Math.random() * (0.999999999999999999 - 0.1) + 0.1
    this.randomNumber = Math.ceil(random * 10 ** this.digits)
  }

  toggleShowRandomNumber(): void {
    this.showRandomNumber = !this.showRandomNumber
  }

  checkAnswer(): void {
    this.correct = parseInt(this.answer) === this.randomNumber
    this.showResult = true
    window.clearTimeout(this.hideResultTimeout)
    this.hideResultTimeout = window.setTimeout(this.hideResult, 3000)
  }

  hideResult(): void {
    this.showResult = false
  }
}
