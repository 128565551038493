import Vue from 'vue'
import Vuex from 'vuex'

import common from '@/store/modules/common'
import loci from '@/store/modules/loci'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    loci,
  },
})
