
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const loci = namespace('loci')
import LociModule from '@/store/modules/loci'
import NoteNewForm from '@/components/NoteNewForm.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'note-new-dialog',
  components: {
    NoteNewForm,
  },
})
export default class NoteNewDialog extends Props {
  get showNoteNewDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showNoteNewDialog
  }

  set showNoteNewDialog(show: boolean) {
    const module = getModule(LociModule, this.$store);
    module.setShowNoteNewDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowNoteNewDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  async save() {
    const form = this.$refs.form as NoteNewForm
    if (form) {
        form.onSave()
    }
  }
}
