
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class'
import { GIT_COMMIT_ID } from '@/common'
import SettingsDialog from '@/components/SettingsDialog.vue'

const common = namespace('common')
import CommonModule from '@/store/modules/common'

const loci = namespace('loci')
import LociModule from '@/store/modules/loci'

import FolderNewDialog from '@/components/FolderNewDialog.vue'
import FolderEditDialog from '@/components/FolderEditDialog.vue'
import NoteNewDialog from '@/components/NoteNewDialog.vue'
import NoteEditDialog from '@/components/NoteEditDialog.vue'
import NotePreviewDialog from '@/components/NotePreviewDialog.vue'
import NoteList from '@/components/NoteList.vue'
import QuestionAnswersDialog from '@/components/QuestionAnswersDialog.vue'
import NumbersExerciseDialog from '@/components/NumbersExerciseDialog.vue'

@Component({
  name: 'App',
  components: {
    FolderNewDialog,
    FolderEditDialog,
    NoteNewDialog,
    NoteEditDialog,
    NotePreviewDialog,
    NoteList,
    QuestionAnswersDialog,
    NumbersExerciseDialog,
    SettingsDialog,
  },
})
export default class App extends Vue {
  drawer = false

  get showNotePreviewDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showNotePreviewDialog
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowNoteNewDialog!: (show: boolean) =>  void

  @loci.Mutation
  setShowFolderNewDialog!: (show: boolean) =>  void

  @loci.Mutation
  setShowNumbersExerciseDialog!: (show: boolean) =>  void

  @common.Action
  loadSettings!: () => void

  @common.Mutation
  setSettingsDialogVisibility!: (visible: boolean) =>  void

  @common.Mutation
  setSettings!: (settings: any) => void

  @loci.Mutation
  removeLocalData!: (changes: any) => void

  @loci.Mutation
  updateLocalData!: (changes: any) => void

  @loci.Mutation
  setSyncing!: (payload: { active: boolean }) => void

  @loci.Mutation
  showError!: (msg: string) => void
  /* eslint-enable no-unused-vars */

  get gitCommitID(): string | undefined {
    return GIT_COMMIT_ID
  }

  async created() {
    await this.loadSettings()
    if (this.theme === 'system') {
      this.onThemeChange(this.theme)
    }

    this.setupEventListeners()
  }

  setupEventListeners() {
    document.addEventListener('visibilitychange', this.onVisibilityChange)

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.onServiceWorkerMessage)
    }
  }

  onVisibilityChange() {
    if (document.hidden) {
      this.onPause()
    } else {
      this.onResume()
    }
  }

  onPause() {
    // do nothing for now
  }

  onResume() {
    // do nothing for now
  }

  openSettingsDialog() {
    this.setSettingsDialogVisibility(true)
  }

  toggleLightDarkTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
  }

  onServiceWorkerMessage(event: any) {
    if (event.data.action === 'test') {
      alert('Test Notification Was Successful!')
    }
  }

  get theme(): string {
    const module = getModule(CommonModule, this.$store);
    return module.settings.theme
  }

  get remoteDatabaseURL(): string {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.remoteDatabaseURL
  }

  get requiresAuthentication(): boolean {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.requiresAuthentication
  }

  @Watch('theme')
  onThemeChange(theme: string) {
    if (theme === 'system') {
      this.watchSystemTheme()
    } else if (theme === 'dark') {
      this.$vuetify.theme.dark = true
    } else if (theme === 'light') {
      this.$vuetify.theme.dark = false
    }
  }

  watchSystemTheme() {
    if (!window.matchMedia) {
      return
    }

    const media = window.matchMedia('(prefers-color-scheme: dark)')
    media.removeEventListener('change', this.onSystemThemeChange)

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }

    media.addEventListener('change', this.onSystemThemeChange)
  }

  onSystemThemeChange(event: any) {
    const theme = event.matches ? "dark" : "light"
    if (theme === 'dark') {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }
  }

  get syncing(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.syncing
  }
}
