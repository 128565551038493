
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

const loci = namespace('loci')
import LociModule from '@/store/modules/loci'
import FolderEditForm from '@/components/FolderEditForm.vue'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'folder-edit-dialog',
  components: {
    FolderEditForm,
  },
})
export default class FolderEditDialog extends Props {
  get showFolderEditDialog(): boolean {
    const module = getModule(LociModule, this.$store);
    return module.showFolderEditDialog
  }

  set showFolderEditDialog(show: boolean) {
    const module = getModule(LociModule, this.$store);
    module.setShowFolderEditDialog(show)
  }

  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setShowFolderEditDialog!: (show: boolean) => void
  /* eslint-enable no-unused-vars */

  save() {
    const form = this.$refs.form as FolderEditForm
    if (form) {
      form.onSave()
    }
  }
}
