
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import { Folder } from '@/lib/loci'
const loci = namespace('loci')

const Props = Vue.extend({
  props: {
    folder: Object as PropType<Folder>,
  }
})

@Component({
  name: 'folder-actions',
})
export default class FolderActions extends Props {
  /* eslint-disable no-unused-vars */
  @loci.Mutation
  setEditFolder!: (folder: Folder | null) => void

  @loci.Mutation
  setShowFolderEditDialog!: (show: boolean) => void

  @loci.Action
  removeFolder!: (folder: Folder) => void
  /* eslint-enable no-unused-vars */

  show() {
    this.setEditFolder(this.folder)
    this.setShowFolderEditDialog(true)
  }
}
