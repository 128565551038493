export interface Note {
  _id: string
  _rev?: string
  type: 'note'
  title: string
  body: string
  tags?: string[]
  folder?: string
  createdAt?: number
  updatedAt?: number
}

export interface Folder {
  _id: string
  _rev?: string
  type: 'folder'
  name: string
  folder?: string
  createdAt?: number
  updatedAt?: number
}

export interface QuestionAnswer {
  question: string[]
  answer: string[]
  status: 'todo' | 'done'
}

function questionAnswerFromLine(line: string): QuestionAnswer {
  const tokens = line
    .split('=')
    .map(line => line.trim())

  return {
    question: tokens[0].split('/').map(token => token.trim()),
    answer: tokens[1].split('/').map(token => token.trim()),
    status: 'todo',
  }
}

export function questionAnswersFromBody(body: string): QuestionAnswer[] {
  return body
    .split('\n')
    .filter(line => isQuestionAnswerLine(line))
    .map(line => questionAnswerFromLine(line))
}

export function strikeDoneQuestionAnswersInBody(body: string, questionAnswers: QuestionAnswer[]): string {
  const doneQuestions = questionAnswers
    .filter(qa => qa.status === 'done')
    .map(qa => qa.question.join(' / '))

  return body
    .split('\n')
    .map(line => {
      if (isQuestionAnswerLine(line) && isDoneQuestionAnswerLine(line, doneQuestions)) {
        return strikeLine(line)
      }
      return line
    })
    .join('\n')
}

function isQuestionAnswerLine(line: string): boolean {
  return line.length > 0 && !(['#', '~'].indexOf(line[0]) >= 0) && line.indexOf('=') >= 0
}

function isDoneQuestionAnswerLine(line: string, doneQuestions: string[]): boolean {
  const question = line.split('=')[0].trim()
  return doneQuestions.indexOf(question) >= 0
}

function strikeLine(line: string): string {
  return '~~' + line + '~~'
}
